(function () {
  'use strict';

  angular
    .module('neo.home.championships.championship.assignMatches')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home.championships.championship.assignMatches', {
        url: '/assign-matches',
        templateUrl: 'home/championships/championship/assign-matches/assign-matches.tpl.html',
        controller: 'AssignMatchesCtrl',
        controllerAs: 'vm',
        resolve: {
          sports: function (Sports) {
            return Sports.query().$promise;
          },
          categories: function (Categories) {
            return Categories.query().$promise;
          },
          genders: function (Genders) {
            return Genders.query().$promise;
          },
          towns: function (Poblacions) {
            return Poblacions.query().$promise;
          },
          facilities: function (Facilities) {
            return Facilities.query().$promise
          },
          //TODO: Revisar
          championshipCalendar: function ($stateParams, ChampionshipCalendarPublic) {
            return ChampionshipCalendarPublic.get({id: $stateParams.championshipId}).$promise;
          },
          entities: function (Entitats) {
            return Entitats.query().$promise
          }
        }
      });
  }
}());
